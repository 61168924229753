.whyus__main_container {
    width: 100%;
    background-color: white;
    padding-top: 12px;

    .whuyus__card_main {
        display: flex;
        flex-direction: row;
        padding-top: 110px;
        justify-content: space-between;
        align-items: center;

        .why__us__sub__div {
            width: 50%;
            color: var(--black);

            .whtus_text {
                font-family: var(--DmSherif);
                font-size: 54px;
                font-weight: 400;
                line-height: 60px;
            }

            .whyus__des {
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                margin-top: 16px;
                font-family: var(--dmSans);
                margin-bottom: 9px !important;
            }

            .feature__with__tick {
                display: flex;
                gap: 12px;
                margin-top: 13px;
            }

            .whyus__list__item {
                font-family: var(--dmSans);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-top: 21px;

                span {
                    font-weight: 700;
                }
            }

            &.text__section {
                padding: 0px 0px 0px 64px;

                &:nth-child(2n+1) {
                    padding: 0px 47px 0 0 !important;
                }

            }
        }

        .why_card_img {
            border-radius: 5px;
            width: 100%;
        }
    }
}

@media only screen and (max-width:768px) {
    .whyus__main_container {
        .whuyus__card_main {
            flex-direction: column;
            padding-top: 25px;
            &.reverse__on__mob{
                flex-direction: column-reverse;
            }

            .why__us__sub__div {
                width: 100%;

                &.text__section {
                    padding: 20px 0;

                    &:nth-child(2n+1) {
                        padding: 20px 0 !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .whyus__main_container {
        .whuyus__card_main {

            .why__us__sub__div {
                .whtus_text {
                    font-size: 40px;
                    line-height: normal;
                }

                .whyus__des {
                    font-size: 18px;
                }

                .whyus__list__item {
                    font-size: 14px;
                }
            }
        }
    }
}
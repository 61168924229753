.fotter__main__box {
    width: 100%;
    background-color: #F0EFEC;

    .fotter__border {
        border-top: 1px solid rgba(0, 0, 0, 0.20);
        padding: 32px 0;
        display: flex;
        gap: 40px;
        justify-content: space-between;
        flex-wrap: wrap;

        .copy__right__section{
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
        }

        a {
            font-family: var(--dmSans);
            color: var(--black);
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            cursor: pointer;
            text-decoration: none;
            transition: all 0.3s ease;

            &:hover {
                opacity: .7;
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .fotter__main__box {
        p{
            font-size: 14px;
        }
        .fotter__border {
            padding: 24px 0;
            a {
                font-size: 14px;
            }
        }
        .fotter__border{
            flex-direction: column;
            gap: 10px;
            .copy__right__section{
                gap: 10px;

            }
        }
    }
}
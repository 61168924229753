.footer__main {
    width: 100%;

    .footer__block {
        width: 100%;
        background-color: var(--primary-color);
        padding: 55px 60px 50px;
        font-family: var(--SpaceGrotesk);
        color: #fff;

        .logo__and__links {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .links__section {
                display: flex;
                gap: 40px;

                button {
                    text-decoration: none;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 400;
                    background-color: transparent;
                    padding: 0;
                    border: none;
                    outline: none;
                }
            }
            .logo__section{
                .linkedin__logo__for__tab{
                    display: none;
                }
            }
        }

        .contact__section {
            width: 100%;
            margin-top: 66px;

            .contact__us__heading {
                background-color: var(--green-color);
                color: #191A23;
                font-size: 20px;
                font-weight: 500;
                width: fit-content;
                padding: 0px 7px;
            }

            .contact__detail__list {
                width: 100%;
                max-width: 325px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 27px;

                .contact__item {
                    font-size: 18px;
                    font-weight: 400;
                    a{
                        color: #fff;
                        font-size: 18px;
                    }
                }
            }
        }

        .center__line {
            width: 100%;
            height: 1px;
            margin: 50px 0px;
            background-color: #fff;
        }

        .copyright__section {
            width: 100%;
            display: flex;
            gap: 40px;
            align-items: center;

            .copyright__text {
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
            }

            .privary__link__section {
                a {
                    text-decoration: underline;
                    color: #FFF;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 28px;
                }
            }
        }
    }
}

@media only screen and (max-width:768px){
    .footer__main{
        .footer__block{
            padding: 35px;
            .logo__and__links{
                flex-direction: column;
                gap: 30px;
                align-items: flex-start;
                .logo__section{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .linkedin__logo__for__tab{
                        display: block;
                    }
                }
                .linkedin__section{
                    display: none !important;
                }
            }
        }
    }
}

@media only screen and (max-width:525px){
    .footer__main{
        .footer__block{
            padding: 35px 20px;
            .logo__and__links{
                .links__section{
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-top: 20px;
                    a{
                        width: 40%;
                    }
                }
                .contact__section{
                    margin-top: 40px;
                }
            }
            .center__line{
                margin: 30px 0px;
            }
            .copyright__section{
                gap: 0;
                flex-wrap: wrap;
                .copyright__text{
                    font-size: 14px;
                }
                .privary__link__section{
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.faq__main__2 {
    background-color: #F0EFEC;

    .shopify__faq__section {
        max-width: 806.39px;
        margin: auto;

        .ant-collapse-header {
            padding: 32px 0 19px;

            .ant-collapse-header-text {
                font-family: var(--dmSans) !important;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                color: #212326;
            }
        }

        .ant-collapse-content {
            background-color: transparent;
            border: none;

            .ant-collapse-content-box {
                padding: 0 16px 16px 0;

                .collapse__content {
                    p {

                        font-family: var(--dmSans) !important;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #212326;
                    }
                }
            }
        }

        .shopify__faqs {
            border: none;
            background-color: transparent;
            width: 100%;
            font-family: var(--dmSans) !important;
            padding-bottom: 60px;

            .toggle__btn {
                border: none;
            }

        }
    }

    .fotter__text {
        color: #000;
        text-align: center;
        font-family: var(--DmSherif);
        font-size: 54px;
        font-weight: 400;
        padding-top: 60px;
        padding-bottom: 20px;
        line-height: 60px;
    }

    .faq__tabs__container {
        border-radius: 5px;
        width: fit-content;
        padding: 3px 5px;
        display: flex;
        margin: auto;
        justify-content: center !important;
        background-color: #FFF;
        margin-bottom: 30px;
        margin-top: 50px;

        button {
            border: none;
            outline: none;
            background-color: transparent;
            color: black;
            font-size: 16px;
            font-weight: 500;
            border-bottom: 1px solid transparent;
            height: 30px;
            font-family: var(--dmSans);
            padding: 0 27px;
            border-radius: 3px;
            line-height: 20px;
            transition: all 0.3s ease;

            &:hover {
                background-color: #EEFAB375;
                color: #79910295;
            }

            &.active__tab {
                background-color: #EEFAB3;
                color: #799102;
            }
        }
    }

    .tab__select__section_2 {
        margin-top: 20px;
        display: block;

        .tab__select {
            width: 100%;
            height: 45px;
            font-size: 22px;
            font-family: var(--dmSans) !important;
        }
    }
}

@media only screen and (max-width:525px) {
    .faq__main__2 {
        .shopify__faq__section {
            .ant-collapse-header {
                .ant-collapse-header-text {
                    font-size: 16px;
                }
            }

            .ant-collapse-content {
                .ant-collapse-content-box {
                    .collapse__content {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .fotter__text {
            font-size: 48px;
        }

        .faq__tabs__container {

            button {
                font-size: 14px;
            }
        }

        .tab__select__section_2 {
            .tab__select {
                font-size: 20px;
            }
        }
    }
}
.main_nav {
    display: flex;
    width: 100%;
    height: 84px;
    padding: 0 33.5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: rgba(0, 84, 80, 0.00);
    position: fixed;
    top: 0;
    left: 0;

    .nav__container {
        display: flex;
        width: 100%;
        height: 48px;
        justify-content: space-between;
        align-items: center;

        .links__and__btn__section {
            display: flex;
            align-items: center;
            gap: 129px;

            .links__section {
                display: flex;
                gap: 31px;
                align-items: center;

                .link_style {
                    text-decoration: none;
                    color: var(--black);
                    font-family: var(--dmSans);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 30px;
                    transition: all 0.3s ease;

                    &:hover {
                        opacity: .7;
                    }
                }
            }
        }
    }

    .get__started__btn {
        text-decoration: none;

        &.login__btn {
            font-family: var(--dmSans);
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
        }
    }

    .menu__btn {
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0;
        width: 32px;
    }

}

.mobile__menu__container {
    height: 0px;
    overflow: hidden;
    transition: height 0.3s ease;
    width: 100%;

    &.active__menu {
        height: 188px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 5px;
    }

    .mob__sub__div {
        border-radius: 5px;
        background-color: #fff;
        padding: 10px;

        .mobile__menu__section {
            .link_style {
                text-decoration: none;
                color: var(--black);
                font-family: var(--dmSans);
                font-size: 14px;
                font-weight: 500;
                line-height: 30px;
                transition: all 0.3s ease;

                &:hover {
                    opacity: .7;
                }
            }
        }
    }
}

.main__empty__div {
    width: 100%;
    height: 84px;
}

@media only screen and (max-width:1200px) {
    .main_nav {
        .nav__container {
            .links__and__btn__section {
                gap: 50px;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .main_nav {
        img {
            max-width: 220px;
        }
    }
}

@media only screen and (max-width:525px) {
    .main_nav {
        padding: 0 15px;

        img {
            max-width: 170px;
        }

        .nav__container {
            .links__and__btn__section {
                .links__section {
                    .link_style {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.shopify__reviews__secion {
    width: 100%;
    padding-bottom: 20px;
    background-color: var(--black);
    position: relative;
    padding: 103px 0;

    .reviews__cards__section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;
    }
}

.btn_style {
    color: white;
    font-size: 16px;
    font-family: var(--dmSans);
    font-weight: 700;
    padding: 12px 40px;
    border-radius: 3px;
    border: 2px solid #000;
    background-color: #000;
    text-align: center;
    transition: all 0.3s ease;
    &:hover {
       opacity: .85;
    }
    @media (max-width: 525px) {
        font-size: 14px;
    }
    &.get__started__btn{
        width: 300px;
        @media (max-width:370px){
            width: 100%;
        }
    }
}
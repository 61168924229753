@import "bootstrap/dist/css/bootstrap.min.css";
@import "antd/dist/reset.css";

:root {
  --primary-color: #191A23;
  --white: #ffffff;
  --black: #000000;
  --grey: #a1a1aa;
  --green-color: #B9FF66;
  --purple: #8E65E4;
  --SpaceGrotesk: "Space Grotesk";
  --karla: "Karla";
  --dmSans:'DM Sans';
  --DmSherif:'DM Serif Display'
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--dmSans);
}

p,
h3,
h2 {
  margin-bottom: 0 !important;
}
.ant-select-item{
  padding: 10px !important;
  font-size: 20px !important;
}
.ant-select-selection-item{
  font-size: 20px !important;
}
.ant-select-item-option-selected{
  background-color: rgb(199, 198, 198) !important;
}
.ant-select-arrow{
  margin-top: -4px !important;
}
.ant-select-selector{
  box-shadow: none !important;
}

.custom__container {
  width: 100%;
  padding: 0 20px;

  @media (min-width:576px) {
    max-width: 540px;
    padding: 0;
    margin: auto;
  }

  @media (min-width:768px) {
    max-width: 720px;
  }

  @media (min-width:992px) {
    max-width: 960px;
  }

  @media (min-width:1200px) {
    max-width: 1140px;
  }

  @media (min-width:1400px) {
    max-width: 1234px;
  }
}
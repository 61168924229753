.navbar__main {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 100px 0px;
  z-index: 999;

  .navbar__border__section {
    width: 100%;
    background-color: #fff;
    height: 74px;
    border: 1px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 35px;
    position: relative;

    .nav__links {
      width: 100%;
      position: absolute;
      left: 0;
      display: flex;
      gap: 40px;
      align-items: center;
      justify-content: center;

      .link__btn {
        color: var(--primary-color);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        border-bottom: 1px solid transparent;
        &.active__link{
          border-bottom: 1px solid var(--primary-color);
        }
      }
    }
    .menu__btn {
      display: none;
    }
  }
  .menu__opened{
    background-color: var(--primary-color);
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    .menu__opened__list{
      width: 100%;
      background-color: var(--primary-color);
      border: none;
      color: #fff;
      font-size: 20px;
      text-align: start;
      padding: 9px;
      &:hover{
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .logo_container{
    cursor: pointer;
    z-index: 9999;
  }
}

.navbar__space {
  width: 100%;
  height: 94px;
}

@media only screen and (max-width:1020px){
  .navbar__main{
    .logo__img{
      max-width: 200px;
    }
  }
}

@media only screen and (max-width:920px) {
  .navbar__main {
    .navbar__border__section {
      padding: 0px 22px;
      .nav__links {
        display: none;
      }

      .menu__btn {
        display: block;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
      }
    }

  }
}

@media only screen and (max-width:768px) {
  .navbar__main {
    padding: 49px 50px 0px;
  }
}

@media only screen and (max-width:525px) {
  .navbar__main {
    padding: 30px 10px 0px;
  }
}
@font-face {
  font-family: "Space Grotesk";
  src: url("SpaceGrotesk-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("SpaceGrotesk-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("SpaceGrotesk-Medium.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("SpaceGrotesk-SemiBold.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("SpaceGrotesk-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

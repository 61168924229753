@font-face {
    font-family: 'Karla';
    src: url('Karla-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}
 

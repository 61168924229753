.top__blur__section__left {
    width: 1104.686px;
    height: 591.329px;
    transform: rotate(-21.426deg);
    flex-shrink: 0;
    border-radius: 9999px;
    background: linear-gradient(0deg, #79DFFF 0%, #D9D9D9 100%);
    position: absolute;
    left: -500px;
    top: -380px;
    filter: blur(50px);
    z-index: -1;
    filter: blur(50px);
}

.top__blur__right {
    width: 700px;
    height: 801px;
    transform: rotate(20.421deg);
    flex-shrink: 0;
    border-radius: 9999px;
    background: linear-gradient(180deg, #D0F224 0%, rgba(208, 242, 36, 0) 100%);
    filter: blur(50px);
    position: absolute;
    top: 110px;
    right: -27px;
    z-index: -1;
}

.main_box_container {
    width: 100%;
    padding: 100px 0 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;


    .main__conatiner {
        max-width: 740px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--black);

        .main__heading {
            text-align: center;
            font-family: var(--DmSherif);
            font-size: 68px;
            font-weight: 400;
            line-height: 78.2px;
            margin-bottom: 0px !important;
        }

        .pera__style {
            width: 100%;
            max-width: 436px;
            text-align: center;
            font-family: var(--dmSans);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            margin-top: 19.5px;
        }

        .pause__text {
            text-align: center;
            font-family: var(--dmSans);
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            margin-top: 21px;
        }

    }

    .hero__btn__section {
        display: flex;
        gap: 20px;
        margin-top: 32px;
        a{
            text-decoration: none;
        }
    }
}

@media only screen and (max-width:525px){
    .main_box_container{
        padding: 25px 0 50px;
        .main__conatiner{
            .main__heading {
                font-size: 40px;
                line-height: inherit;
            }
    
            .pera__style {
                font-size: 18px;
            }
    
            .pause__text {
                font-size: 18px;
            }
        }
    }
}
.thanks__hero {
    width: 100%;
    max-width: 607px;
    margin:60px auto 0;
    font-family: var(--dmSans);

    .welcome__tag {
        border-radius: 3px;
        background: #EEFAB3;
        width: 131.44px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: #799102;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
    }

    .get__started__heading {
        color: #000;
        text-align: center;
        font-size: 54px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
        font-family: var(--DmSherif);
        margin-top: 17px;
    }

    .get__started__description {
        max-width: 436px;
        margin: 17px auto 0;
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
    }

    .what__next__cards__section {
        display: flex;
        flex-direction: column;
        margin: 43px auto 0;
        gap: 29px;
        justify-content: center;
        max-width: 429px;
        width: 100%;

        .what__next__card {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 26px;

            h3 {
                color: var(--black);
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: -0.48px;
            }

            p {
                color: var(--black);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-top: 16px;
                a{
                    color: var(--black);
                    text-decoration: underline;
                }
            }
        }
    }
}
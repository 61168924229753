.reviw__card__box {
    width: calc(50% - 12px);
    border-radius: 5px;
    border: 1px solid #D7DDE0;
    background: #FFF;
    padding: 25px 39px 25px 25px;
    display: flex;
    flex-direction: row;
    gap: 24px;


    .review__card__text {
        color: #353640;
        font-family: var(--dmSans);
        font-size: 16px;
        font-weight: 500;
    }

    .reviw__description {
        color: #4F5C65;
        font-family: var(--dmSans);
        font-size: 14px;
        font-weight: 500;
        padding-top: 10px;
    }

    img {
        border-radius: 100%;
        max-width: 72px;
        max-height: 72px;
    }
    svg{
        margin-bottom: 3px;
    }
}

@media only screen and (max-width:768px) {
    .reviw__card__box {
        width: 100%;
        padding: 25px 20px 25px 20px;
    }
}

@media only screen and (max-width:525px) {
    .reviw__card__box {
        .review__card__text {
            font-size: 14px;
        }

        .reviw__description {
            font-size: 12px;
        }
        img {
            max-width: 58px;
            max-height: 58px;
        }
    }
}
@font-face {
    font-family: 'DM Sans';
    src: url('./static/DMSans-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'DM Sans';
    src: url('./static/DMSans-Bold.ttf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'DM Sans';
    src: url('./static/DMSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('./static/DMSans-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('./static/DMSans_18pt-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('./static/DMSans_18pt-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('./static/DMSans-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('./static/DMSans-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}
 

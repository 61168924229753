.project__card__section {
    justify-content: space-between;
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: -54px;


    .project__card__img {
        width: calc(25% - 27px);
        border-radius: 10px;
    }
}

@media only screen and (max-width:991px){
    .project__card__section{
        margin-bottom: -28px;
    }
}

@media only screen and (max-width:768px){
    .project__card__section{
        margin-bottom: -19px;
    }
}

@media only screen and (max-width:525px){
    .project__card__section{
        margin-bottom: -12px;
    }
}
.dark-btn {
  display: inline-flex;
  padding: 20px 35px;
  border: none;
  outline: none;
  align-items: flex-start;
  background-color: #191a23;
  color: #fff;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.plans__main__container {
    padding: 122px 0 0;

    .plans__text {
        text-align: center;
        color: #000;
        font-family: var(--DmSherif);
        font-size: 54px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
    }

    .plan__card__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 44px;
        flex-wrap: wrap;

        .plan_card_box {
            width: calc(50% - 20px);
            border: 1px solid #D7DDE0;
            background: #FFF;
            border-radius: 5px;

            .list__section {
                .list__item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-top: 10px;

                    .list___name {
                        font-family: var(--dmSans);
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 700;
                    }

                    .list__text {
                        font-family: var(--dmSans);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                    }

                    .list__parent {
                        padding-top: 10px;
                    }
                }
            }

            .btn__section {
                display: flex;
                gap: 10px;
                flex-direction: column;
                align-items: center;
                margin-top: 27px;

                .link__style {
                    color: #000;
                    font-family: var(--dmSans);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    text-decoration-line: underline;
                    margin-top: 20px;
                    border: none;
                    outline: none;
                    background-color: transparent;
                }
            }

            .card__header {
                background: #D0F224;
                display: flex;
                width: 100%;
                padding: 9px;
                flex-direction: column;
                align-items: center;

                .header_text {
                    color: #000;
                    text-align: center;
                    font-family: var(--dmSans);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 142.857% */
                }
            }

            .card__text__section__2 {
                padding: 17px 24px;

                .header__align {
                    display: flex;
                    justify-content: space-between;

                    .heading__text {
                        font-family: var(--dmSans);
                        color: var(--black);
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32px;
                    }

                    .plans__btn {
                        border: none;
                        border-radius: 5px;
                        background: #45F298;
                        color: var(--black);
                        font-family: var(--dmSans);
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;
                        padding: 3.5px 16px;
                    }
                }

                .header__text__style {
                    color: var(--black);
                    font-family: var(--dmSans);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 20px;
                    margin-top: 14px;
                }

                .number__text {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    font-family: var(--dmSans);
                }

                .card__price__section {
                    display: flex;
                    align-items: center;
                    margin-top: 22px;
                    gap: 12px;

                    .price__text {
                        color: #000;
                        font-family: var(--dmSans);
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 60px;
                    }
                }

                .border__div {
                    width: 100%;
                    height: 1px;
                    background-color: #D7DDE0;
                    margin: 24px 0;
                }
            }
        }
    }
}

.workit__card__box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap-reverse;
    margin-top: 33px;
    row-gap: 23px;
    padding-bottom: 149px;
    flex-wrap: wrap;

    .card__work_box {
        width: calc(33% - 12px);
        padding: 32px;
        border-radius: 5px;
        border: 1px solid #D7DDE0;
        background: rgba(24, 5, 5, 0);

        .icon__box {
            width: 64px;
            height: 64px;

            img {
                max-width: 64px;
            }
        }

        .card__heading {
            color: #000;
            font-family: var(--dmSans);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.48px;
            margin-top: 24px;
        }

        .card__text {
            color: #000;
            font-family: "DM Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-top: 16px;
        }
    }
}

@media only screen and (max-width:768px) {
    .plans__main__container {
        .plan__card__container {
            gap: 20px;

            .plan_card_box {
                width: 100%;
            }
        }
    }

    .workit__card__box {
        .card__work_box {
            width: calc(50% - 12px);
        }
    }
}

@media only screen and (max-width:525px) {
    .plans__main__container {
        .plans__text {
            font-size: 48px;
        }

        .plan__card__container {
            gap: 20px;

            .plan_card_box {
                width: 100%;

                .list__section {
                    .list__item {

                        .list___name {
                            font-size: 12px;
                        }

                        .list__text {
                            font-size: 12px;
                        }
                    }
                }

                .btn__section {

                    .link__style {
                        font-size: 14px;
                    }
                }

                .card__header {

                    .header_text {
                        font-size: 12px;
                    }
                }

                .card__text__section__2 {
                    padding: 17px;
                    .header__align {

                        .heading__text {
                            font-size: 22px;
                        }

                        .plans__btn {
                            font-size: 12px;
                        }
                    }

                    .header__text__style {
                        font-size: 16px;
                    }

                    .number__text {
                        font-size: 12px;
                    }

                    .card__price__section {

                        .price__text {
                            font-size: 58px;
                        }
                    }
                }
            }
        }
    }

    .workit__card__box {

        .card__work_box {
            width: 100%;

            .card__heading {
                font-size: 22px;
            }

            .card__text {
                font-size: 16px;
            }
        }
    }
}
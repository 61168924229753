.thanks__video__conatiner {
    padding: 57px 0;

    .video__box {
        display: inline-flex;
        align-items: flex-end;
        width: 100%;
        border-radius: 5px;
        background: #E9EEF1;
        position: relative;

        .play__pause__btn {
            background-color: #EEFAB3;
            width: 130px;
            height: 130px;
            border: none;
            outline: none;
            position: absolute;
            left: 0;
            bottom: 0;

            @media (max-width: 768px) {
                width: 100px;
                height: 100px;

                svg {
                    height: 32px;
                }

                .play__icon {
                    width: 100px;
                    height: 100px;
                }
            }

            @media (max-width: 525px) {
                width: 60px;
                height: 60px;

                svg {
                    height: 20px;
                }

                .play__icon {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}
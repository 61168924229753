/* @font-face {
    font-family: 'DM Serif Display';
    src: url('./DMSerifDisplay-Regular.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DM Serif Display';
    src: url('./DMSerifDisplay-Regular.ttf');
    font-weight: 400;
    font-style: normal;
} */
@font-face {
    font-family: 'DM Serif Display';
    src: url('./DMSerifDisplay-Regular.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'DM Serif Display';
    src: url('./DMSerifDisplay-Regular.ttf');
    font-weight: 600;
    font-style: normal;
}

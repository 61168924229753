.cabilities__cards__section {
    width: 100%;
    background-color: black;
    padding: 80px 0 96px;

    .capablities__heading {
        color: #FFF;
        text-align: center;
        font-family: var(--DmSherif);
        font-size: 54px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: -0.96px;
    }

    .capablities__text {
        color: #FFF;
        text-align: center;
        font-family: var(--dmSans);
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.44px;
        max-width: 832px;
        margin: 16px auto 0;
    }

    .capablities__main {
        display: flex;
        justify-content: space-between;
        color: #FFF;
        gap: 15px;
        width: 100%;
        margin-top: 64px;
        flex-wrap: wrap;

        .link__conatiner {
            margin-top: 24px;

            .link__style {
                color: #FFF;
                font-family: var(--dmSans);
                font-size: 16px;
                padding-top: 15px !important;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0.36px;
                text-decoration-line: underline;
                border: none;
                outline: none;
                padding: 0;
                background-color: transparent;
            }
        }

        .cabilties__img {
            width: calc(50% - 12px);

            img {
                width: 100%;
                border: 1.5px solid #fff;
                border-radius: 5px;
            }

            .list__style {
                color: #FFF;
                font-family: var(--dmSans);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 125% */
            }

            .list__heading {
                color: #FFF;
                font-family: var(--dmSans);
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -0.8px;
                margin-top: 23px;
            }

            .list__text {
                color: #FFF;
                font-family: var(--dmSans);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-top: 16px;
                padding-right: 50px;
            }

            .name__des {
                color: #FFF;
                font-family: var(--dmSans);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                margin-top: 20px;
            }

            .feature__list__item {
                display: flex;
                margin-top: 12px;
                gap: 10px;
            }



        }
    }


}

@media only screen and (max-width:768px) {
    .cabilities__cards__section {
        .capablities__main {
            .cabilties__img {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .cabilities__cards__section {
        .capablities__heading {
            font-size: 48px;
        }

        .capablities__text {
            font-size: 18px;
        }

        .capablities__main {
            .link__conatiner {
                .link__style {
                    font-size: 14px;
                }
            }

            .cabilties__img {
                .list__style {
                    font-size: 14px;
                }

                .list__heading {
                    font-size: 22px;
                }

                .list__text {
                    font-size: 18px;
                }

                .name__des {
                    font-size: 12px;
                }
            }
        }


    }
}
.work__main {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center !important;
    margin-top: 125px;

    .workit__btn {
        border-radius: 3px;
        background: #EEFAB3;
        color: #799102;
        border: none;
        padding: 5px 20px;
        font-family: var(--dmSans);
    }

    .process__heading {
        color: #000;
        text-align: center;
        font-family: var(--DmSherif);
        font-size: 54px;
        font-weight: 400;
        line-height: 60px;
        max-width: 607px;
        margin: 16px auto 0;
    }

    .process__card__section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        flex-wrap: wrap;

        .process__card {
            display: flex;
            width: calc(33% - 12px);
            padding: 32px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            border-radius: 5px;
            border: 1px solid #D7DDE0;
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.20);

            .proccess__card__title {
                color: #000;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                font-family: var(--dmSans);
                margin-top: 8px;
            }

            .proccess__card__description {
                color: #000;
                font-family: var(--dmSans);
                font-size: 18px;
                font-style: normal;
                text-align: start;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }

    .video__conatiner {
        padding-bottom: 122px;
        padding-top: 66px;

        .video__box {
            display: inline-flex;
            align-items: flex-end;
            width: 100%;
            border-radius: 5px;
            background: #E9EEF1;
            position: relative;

            .play__pause__btn {
                background-color: #EEFAB3;
                width: 130px;
                height: 130px;
                border: none;
                outline: none;
                position: absolute;
                left: 0;
                bottom: 0;

                @media (max-width: 768px) {
                    width: 100px;
                    height: 100px;
    
                    svg {
                        height: 32px;
                    }
    
                    .play__icon {
                        width: 100px;
                        height: 100px;
                    }
                }
    
                @media (max-width: 525px) {
                    width: 60px;
                    height: 60px;
    
                    svg {
                        height: 20px;
                    }
    
                    .play__icon {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width:768px) {
    .work__main {
        .process__card__section {
            gap: 20px;

            .process__card {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .work__main {
        .process__heading {
            font-size: 48px;
        }

        .process__card__section {

            .process__card {

                .proccess__card__title {
                    font-size: 22px;
                }

                .proccess__card__description {
                    font-size: 16px;
                }
            }
        }
    }
}
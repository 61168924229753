
.btnnoraml_style {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    padding: 12px 40px;
    align-items: center;
    border-radius: 3px;
    text-align: center;
    border: 2px solid #000;
    font-family:var(--dmSans);
    background-color: transparent;
    transition: all 0.3s ease;
    &:hover{
        background-color: var(--black);
        color: #fff;
    }
    &.bg__black{
        background-color: var(--black);
        color: #fff;
        &:hover{
           opacity: .85;
        }
    }
    &.login__btn{
        padding: 12px 20px;
    }
    @media (max-width: 525px){
        font-size: 14px;
        &.small__on__mob{
            padding: 8px 18px;
        }
    }
}